import React, { useState } from 'react';
import axios from 'axios';
import { Button, Modal, Label, Textarea, Select } from 'flowbite-react';
import { HiOutlineChatAlt2, HiOutlineCheckCircle, HiOutlineXCircle, HiOutlineEmojiHappy } from 'react-icons/hi';
import useCookie from '../../context/useCookie';

const FeedbackButton = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [feedbackType, setFeedbackType] = useState('');
  const [description, setDescription] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const userInfo = useCookie('user');
  const user = userInfo ? JSON.parse(userInfo) : null;
  const { email } = user || {};

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);

    const payload = {
      type: feedbackType,
      description: description,
      timestamp: new Date().toISOString(),
      email: email,
    };

    try {
      const response = await axios({
        method: 'post',
        url: process.env.REACT_APP_INSERT_FEEDBACK_URL,
        data: payload,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Basic ${btoa(`${process.env.REACT_APP_API_USER}:${process.env.REACT_APP_API_PASS}`)}`
        }
      });

      if (response.status !== 200) {
        throw new Error('Failed to submit feedback');
      }

      setIsSubmitted(true);
      setTimeout(() => {
        setIsOpen(false);
        setIsSubmitted(false);
        setFeedbackType('');
        setDescription('');
      }, 3000);
    } catch (err) {
      setError('Failed to submit feedback. Please try again.');
      // console.error('Error submitting feedback:', err);
    } finally {
      setIsLoading(false);
    }
  };

  if (!email) {
    return null; // Don't render the button if user is not logged in
  }

  return (
    <>
      <Button
        onClick={() => setIsOpen(true)}
        className="fixed bottom-4 left-1/2 transform -translate-x-1/2 bg-blue-600 hover:bg-blue-700 text-white text-sm py-2 px-4 rounded-full shadow-lg transition duration-300 ease-in-out z-50 flex items-center"
      >
        <HiOutlineChatAlt2 className="mr-2 text-lg" />
        <span>Feedback</span>
      </Button>
      <Modal show={isOpen} onClose={() => setIsOpen(false)} size="md" className="!z-50">
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white rounded-lg shadow-2xl overflow-hidden max-w-md w-full mx-4">
            <div className="bg-gradient-to-r from-blue-600 to-blue-800 text-white py-4 px-6">
              <h3 className="text-xl font-semibold">Provide Feedback</h3>
            </div>
            <div className="p-6">
              {isSubmitted ? (
                <div className="text-center text-green-600 py-8">
                  <HiOutlineEmojiHappy className="text-6xl mx-auto mb-4" />
                  <h4 className="text-2xl font-bold mb-4">Thank you for your feedback!</h4>
                  <p>We appreciate your input and will review it shortly.</p>
                </div>
              ) : (
                <form onSubmit={handleSubmit} className="space-y-4">
                  <div>
                    <Label htmlFor="feedbackType" className="text-sm font-medium text-gray-700 mb-1 block">Feedback Type</Label>
                    <Select
                      id="feedbackType"
                      required
                      value={feedbackType}
                      onChange={(e) => setFeedbackType(e.target.value)}
                      className="w-full rounded-md border-gray-300 focus:border-blue-500 focus:ring focus:ring-blue-200 text-sm"
                    >
                      <option value="">Select a type</option>
                      <option value="bug">Report Bug</option>
                      <option value="feature">Request New Tool</option>
                      <option value="integration">Suggest Integration</option>
                      <option value="other">Other</option>
                    </Select>
                  </div>
                  <div>
                    <Label htmlFor="description" className="text-sm font-medium text-gray-700 mb-1 block">Description</Label>
                    <Textarea
                      id="description"
                      required
                      rows={4}
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                      className="w-full rounded-md border-gray-300 focus:border-blue-500 focus:ring focus:ring-blue-200 text-sm"
                      placeholder="Please provide details about your feedback..."
                    />
                  </div>
                  {error && <p className="text-red-500 text-sm">{error}</p>}
                  <div className="flex justify-end space-x-2">
                    <Button onClick={() => setIsOpen(false)} className="bg-gray-200 hover:bg-gray-300 text-gray-800 font-medium py-2 px-4 rounded-md transition duration-300 text-sm">
                      Cancel
                    </Button>
                    <Button type="submit" disabled={isLoading} className="bg-blue-600 hover:bg-blue-700 text-white font-medium py-2 px-4 rounded-md transition duration-300 text-sm">
                      {isLoading ? 'Submitting...' : 'Submit'}
                    </Button>
                  </div>
                </form>
              )}
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default FeedbackButton;